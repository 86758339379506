/**
 * 縦横比計算
 **/
export function ratio() {
	let result;
	let timer = null;
	const change = function(){
		const winWidth = $(window).width();
			const winHeight = $(window).height();
			result = winWidth/winHeight;
			console.log(result);
			if(result>=1.6){
				$('.bg-video video').addClass('vertical');
			}else{
				$('.bg-video video').removeClass('vertical');
			}
	}
	change();
	$(window).on('resize', function() {
		clearTimeout(timer);
		timer = setTimeout(function() {
			// リサイズ完了後の処理
			change();
		}, 200);
	});
	return result;
}