import { scrollAnime } from "./modules/app.js";
import { intro } from "./modules/intro.js";
import { ratio } from "./modules/ratio.js";

$('.section__header-title').each(function(index, obj) {
	const $this = $(this);
	$this.find('.section__header-inner').each(function(i, o) {
		const $this = $(this);
		$this.prepend('<span class="text-bg--before">');
		$this.append('<span class="text-bg--after">');
		$this.attr('id', 'anm_text' + index + '_' + (i + 1));
	});
});

window.imgWidth = function() {
	var $fixedItem = $('#fixedItem');
	var fixedItemWidth = $fixedItem.width() * 0.97;
	var fixedItemHeight = $fixedItem.height() * 0.97;
	var fixedItemTop = $fixedItem.css('top');
	var fixedItemLeft = $fixedItem.css('left');
	var $appImg = $('.app-img__list');
	$appImg.css({
		'top': fixedItemTop,
		'left': fixedItemLeft,
		'width': fixedItemHeight + 'px',
		'height': fixedItemWidth + 'px'
	});
}

function snsLink(){
	$('.sns_link').each(function(){
		var $this = $(this);
		var href = $this.attr('href');
		var url = window.location.href;
		$this.attr('href',href+url)
	});
}

if (!$.browser.mobile) {
	ratio();
	intro();
	window.introDeferrd.done(function() {
		scrollAnime();
		$('#vid')[0].play();
		$('.bg-video video')[0].play();
	});
} else {
	$('.intro').hide();
	$('body').removeClass('fixed');
	$('.bg-video').find('video').hide();
	$('#vid').remove();
	$('.bg-video video').remove();

	var fadein = $('.js-slide--wrap').addClass('fadein-js');
	var player = $('.js-slide--player').addClass('fadein-js');
	player.children().slice(1).hide();
	fadein.children().slice(1).hide();
	setInterval(function() {

		player.children().eq(0).fadeOut(200)
			.next().fadeIn()
			.end().appendTo(player);

		fadein.children().eq(0).fadeOut(200)
			.next().fadeIn()
			.end().appendTo(fadein);

	}, 2000);


}


var timer = false;
$(window).on('resize', function() {
	if (timer !== false) {
		clearTimeout(timer);
	}
	timer = setTimeout(function() {
		window.imgWidth();
	}, 200);
});
// プラグイン実行など
$(window).on('load', function() {
	// setTimeout(function(){
	// 	// document.getElementById('vid').play();
	// },500);
	imgWidth();
	snsLink();
	console.log($('.section01'));
});