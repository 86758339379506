/**
 * 関数説明
 **/
window.introDeferrd = $.Deferred();
export function intro() {
	const bg = document.querySelector('.intro');
	const logo = document.querySelector('.section__header--first');
	const playerLeft = document.querySelector('.intro__player--left');
	const playerRight = document.querySelector('.intro__player--right');
	var fadeLogo = function(){
		const tl = new TimelineMax({});
		tl.add('player')
				.to(bg,0.5,{backgroundColor:'#333', ease: Power0.easeIn})
				.to(playerLeft,0.5,{opacity: 1,left: 0,ease: Elastic.easeOut})
				.to(playerRight,0.5,{opacity: 1,right: 0,ease: Elastic.easeOut})
				.to(logo,0.5,{scale:1,opacity: 1,ease: Elastic.easeOut})
			.add('fadeOut')
				.to(bg,1.5,{backgroundColor:'none', ease: Power0.easeOut,
					onComplete:function(){
						const body = document.querySelector('body');
						body.className = '';
						$(bg).fadeOut();
						window.introDeferrd.resolve();
					}
				},'player+=3')
				.to(playerLeft,0.5,{opacity: 0,left: 0,ease: Power0.easeOut},'fadeOut-=0.1')
				.to(playerRight,0.5,{opacity: 0,right: 0,ease: Power0.easeOut},'fadeOut-=0.1')
			.add('sectionFadeIn')
				.to('#fixedItem',0.3,{opacity:1},'fadeOut-=0.1');
			return tl;
	}
	// var player = function(){
	// 	const tl = new TimelineMax();
	// 	tl.add('playerFade')
	// 		.to(playerLeft,0.3,{opacity: 1,left: 0,ease: Power0.easeOut})
	// 		.to(playerRight,0.3,{opacity: 1,right: 0,ease: Power0.easeOut},'logoFade-=0.1');
	// 		return tl;
	// }

	const master = new TimelineMax();
	master.add(fadeLogo(),'fadeLogo');
	master.timeScale(0.6);
	// fadeLogo();
}