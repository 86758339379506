/**
 * 関数説明
 **/
export function scrollAnime() {
	function fadeText(obj){
		var $target = obj;
		$target.eq(0).addClass('active');
		setTimeout(function(){
			$target.eq(0).addClass('after');
		},300);

		setTimeout(function(){
			$target.eq(1).addClass('active');
			setTimeout(function(){
				$target.eq(1).addClass('after');
			},300);
		},300);
	}
	var controller = new ScrollMagic.Controller();
	/***
	* animation00
	**/
	var $fixedItem = $('#fixedItem');
	var fixedItem01 = new ScrollMagic.Scene({ triggerElement: "#section01",offset:$("#section01").height(), triggerHook: 0.5, duration: ($('.section__dummy:last').offset().top + $('.section__dummy:last').height()) + ($('#section01').height()/4) })
		.setPin('#fixedItem',{pushFollowers: false})
		// .addIndicators({name:'fixedItem01'})
		.addTo(controller);

	var fixedItem02 = new ScrollMagic.Scene({ triggerElement: "#section01",offset:$("#section01").height(), triggerHook: 0.5, duration: ($('.section__dummy:last').offset().top + $('.section__dummy:last').height()) + ($('#section01').height()/4) })
		.on('enter',function(){
			$fixedItem.addClass('hasScroll');
			$fixedItem.removeClass('end');
			$('.app-img__list').removeClass('stop');
			setTimeout(function(){
				window.imgWidth();
			},200);
		})
		.on('leave',function(e){
			if(e.progress===0){
				$fixedItem.removeClass('hasScroll');
				$('.app-img__list').removeClass('stop');
			}else{
				$fixedItem.addClass('end');
				var top = $fixedItem.offset().top;
				$('.app-img__list').addClass('stop');
				$('.app-img__list').css({
					'top': top + 'px',
					'left': '50%'
				});
			}
		})
		// .addIndicators({name:'fixedItem02'})
		.addTo(controller);
	/***
	* animation01
	**/
	var tween01_1 = new TimelineMax();
	tween01_1
		.add('rotate')
			.to("#fixedItem", 2, { y:0,rotation: 90, ease: Linear.easeNone })
			.to(".app-img__list", 1, { opacity:1, ease: Linear.easeNone })
		.add('videFadeOut')
			.to("#fixed_item--video", 1, {opacity:0 ,ease: Linear.easeNone});

	var scene01_1 = new ScrollMagic.Scene({ triggerElement: "#section02_after", offset: $("#section01").height()/2, duration: $("#section01").height()/4 })
		.setTween(tween01_1)
		.on('enter',function(){
			window.imgWidth();
		})
		// .addIndicators({name:'scene01_1'})
		.addTo(controller);

	var tween01_2 = new TimelineMax();
	tween01_2
		.add('.app-img01')
			.to('.app-img01 img',1,{y:'-60%' ,ease: Linear.easeNone});

	var scene01_2 = new ScrollMagic.Scene({ triggerElement: "#section02_after", offset: $("#section01").height()/3 + $("#section01").height()/4, duration: '250%'})
		.setTween(tween01_2)
		.on('enter',function(){
			$('.app-img').hide();
			$('.app-img01').show();
		})
		.on('leave',function(e){
			if(e.progress===0){
				$('.app-img01').hide();
				$('.app-img02').hide();
			}else{
				$('.app-img01').fadeOut();
				$('.app-img02').fadeIn();
			}
		})
		// .addIndicators({name:'scene01_2'})
		.addTo(controller);
	/***
	* animation02
	**/
	var tween02 = new TimelineMax();

	const $secTit02 = $('#section02_tit .section__header-inner');
	var scene02_1 = new ScrollMagic.Scene({ triggerElement: "#section02_after", offset: $("#section01").height()/1.8, duration: '300%' })
		.on('enter',function(){
			$('#section__body01').removeClass('active');
			$('#section__body02').addClass('active');
		})
		.on('leave',function(){
			$('#section__body01').addClass('active');
			$('#section__body02').removeClass('active');
		})
		// .addIndicators({name:'scene02_1'})
		.addTo(controller);

	var scene02_2 = new ScrollMagic.Scene({ triggerElement: "#section02", offset: $("#section01").height()/2.2, duration: '320%' })
		.on('enter',function(){
			fadeText($secTit02);
			setTimeout(function(){
				$('#section__body01').addClass('active');
			},800);
			$('#section__body02').removeClass('active');
		})
		.on('leave',function(){
			$secTit02.removeClass('active');
			$secTit02.removeClass('after');
			$('#section__body01').removeClass('active')
		})
		// .addIndicators({name:'scene02_2'})
		.addTo(controller);

	var scene02_3 = new ScrollMagic.Scene({ triggerElement: "#section02", offset: $('#section01').height()/2.2, duration:'300%' })
		.setPin('#section02_tit')
		.on('enter',function(){
			$('#section02_tit').removeClass('end');
		})
		.on('leave',function(e){
			console.log(e.progress);
			if(e.progress===0){
				$('#section02_tit').removeClass('end');
			}else{
				$('#section02_tit').addClass('end');
			}
		})
		// .addIndicators({name:'scene02_3'})
		.addTo(controller);

	var scene02_4 = new ScrollMagic.Scene({ triggerElement: "#section02", offset: $('#section01').height()/2.2, duration: '300%' })
		.setPin('#section__inner01')
		// .addIndicators({name:'scene02_4'})
		.addTo(controller);

	/***
	* animation03
	**/
	var scene03 = new ScrollMagic.Scene({ triggerElement: "#section04", offset: $('#section01').height()/1.8, duration: '200%'})
		.setPin('#section04')
		.on('enter',function(){
			fadeText($('#section04_tit .section__header-inner'));
			setTimeout(function(){
				$('#logo__list01').addClass('active');
				$('#section04 .section__header-txt').addClass('active');
				$('.app-img01').fadeOut();
				$('.app-img02').fadeIn();
			},800);
		})
		.on('leave',function(e){
			$('#logo__list01').removeClass('active');
			if(e.progress===0){
				$('.app-img').fadeOut();
				$('#section04_tit .section__header-inner').removeClass('active');
				$('#section04_tit .section__header-inner').removeClass('after');
				$('#section04 .section__header-txt').removeClass('active');
				$('.app-img01').fadeIn();
			}else{
				$('.app-img03').eq(0).fadeIn();
			}
			// $('#section__body01').removeClass('active')
		})
		// .addIndicators({name:'scene03'})
		.addTo(controller);
	/***
	* animation04
	**/

	var scene04_1 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/2, duration: '600%'  })
		.setPin('.bg-text--player')
		.on('enter',function(){
			$('.app-img01').fadeOut();
			$('.app-img02').fadeOut();
			fadeText($('#section05_tit .section__header-inner'));
			setTimeout(function(){
				$('#section05 .section__header-txt').addClass('active');
				$('#logo__list').addClass('active');
			},800);
			// $('#section05_tit .section__header-inner').addClass('active');
		})
		.on('leave',function(e){
			if(e.progress===0){
				$('.app-img02').fadeIn();
				$('.app-img03').fadeOut();
				$('#section05_tit .section__header-inner').removeClass('active');
				$('#section05_tit .section__header-inner').removeClass('after');
				$('#section05 .section__header-txt').removeClass('active');
				$('#logo__list').removeClass('active');
			}
		})
		// .addIndicators({name:'scene04_1'})
		.addTo(controller);

	var scene04_2 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/2, duration: '100%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').eq(0).addClass('active');
			$('.player__item').eq(0).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').hide();
			$('.app-img03').eq(0).show();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(0).removeClass('active');
			$('.player__item').eq(0).removeClass('active');
		})
		// .addIndicators({name:'scene04_2'})
		.addTo(controller);

	var scene04_3 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/3 + $('#section01').height(), duration: '100%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').removeClass('active');
			$('.logo__list--single').find('.logo__item--single').eq(1).addClass('active');
			$('.player__item').removeClass('active');
			$('.player__item').eq(1).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').fadeOut();
			$('.app-img03').eq(1).fadeIn();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(1).removeClass('active');
			$('.player__item').eq(1).removeClass('active');
		})
		// .addIndicators({name:'scene04_3'})
		.addTo(controller);

	var scene04_4 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/3 + ($('#section01').height()*2), duration: '100%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').eq(2).addClass('active');
			$('.player__item').eq(2).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').fadeOut();
			$('.app-img03').eq(2).fadeIn();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(2).removeClass('active');
			$('.player__item').eq(2).removeClass('active');
		})
		// .addIndicators({name:'scene04_4'})
		.addTo(controller);

	var scene04_5 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/3 + ($('#section01').height()*3), duration: '100%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').eq(3).addClass('active');
			$('.player__item').eq(3).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').fadeOut();
			$('.app-img03').eq(3).fadeIn();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(3).removeClass('active');
			$('.player__item').eq(3).removeClass('active');
		})
		// .addIndicators({name:'scene04_5'})
		.addTo(controller);

	var scene04_6 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/3 + ($('#section01').height()*4), duration: '100%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').eq(4).addClass('active');
			$('.player__item').eq(4).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').fadeOut();
			$('.app-img03').eq(4).fadeIn();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(4).removeClass('active');
			$('.player__item').eq(4).removeClass('active');
		})
		// .addIndicators({name:'scene04_6'})
		.addTo(controller);

	var scene04_7 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/3 + ($('#section01').height()*5), duration: '150%' })
		.on('enter',function(){
			$('.logo__list--single').find('.logo__item--single').eq(5).addClass('active');
			$('.player__item').eq(5).addClass('active');
			$('.app-img02').fadeOut();
			$('.app-img03').fadeOut();
			$('.app-img03').eq(5).fadeIn();
		})
		.on('leave',function(){
			$('.logo__list--single').find('.logo__item--single').eq(5).removeClass('active');
			$('.player__item').eq(5).removeClass('active');
		})
		// .addIndicators({name:'scene04_7'})
		.addTo(controller);

	var $sectionHeder05 = $('#section05_header');
	var scene05 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/2, duration: $('#section01').height()*5.3  })
		.setPin('#section05_header')
		.on('enter',function(e){
				$sectionHeder05.removeClass('hasEnd');
				console.log('scene06 enter!!!');
		})
		.on('leave',function(e){
			if(e.progress===0){
				$sectionHeder05.removeClass('hasEnd');
			}else{
				$sectionHeder05.addClass('hasEnd');
			}
		})
		.on("start", function (e) {
			$sectionHeder05.removeClass('hasEnd');
		})
		// .addIndicators({name:'scene05'})
		.addTo(controller);
	var $logoList = $('#logo__list');
	var scene06 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/2, duration: $('#section01').height()*5.3 })
		.setPin('#logo__list')
		.on('enter',function(e){
				$logoList.removeClass('hasEnd');
				console.log('scene06 enter!!!');
		})
		.on('leave',function(e){
			if(e.progress===0){
				$logoList.removeClass('hasEnd');
			}else{
				$logoList.addClass('hasEnd');
			}
		})
		.on("start", function (e) {
			$logoList.removeClass('hasEnd');
		})
		// .addIndicators({name:'scene06'})
		.addTo(controller);
	var $playerList = $('#player__list');
	var scene07 = new ScrollMagic.Scene({ triggerElement: "#section05", offset: $('#section01').height()/2, duration: $('#section01').height()*5.3 })
		.setPin('#player__list')
		.on('enter',function(e){
				$playerList.removeClass('hasEnd');
		})
		.on('leave',function(e){
			$playerList.addClass('hasEnd');
		})
		.on("start", function (e) {
			$playerList.removeClass('hasEnd');
		})
		// .addIndicators({name:'scene07'})
		.addTo(controller);


}